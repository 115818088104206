<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('paymentmethod-modal', {id: 0}, afterSave, {width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.paymentmethods.label.name') }}</b-th>
                            <b-th>{{ $t('settings.paymentmethods.label.value') }}</b-th>
                            <b-th>{{ $t('settings.paymentmethods.label.actions') }}</b-th>
                        </b-thead>
                        <draggable :class="{[`cursor-grabbing`]: dragging === true}" v-model="paymentmethods"
                                   group="columns" @start="dragging = true" @end="dragging = false" tag="tbody"
                                   handle=".handle">
                            <b-tr v-for="paymentmethod in paymentmethods" :key="paymentmethod.id">
                                <b-td>{{ paymentmethod.name }}</b-td>
                                <b-td>{{ paymentmethod.value }}</b-td>
                                <b-td>
                                    <b-button
                                        @click="$root.$children[0].openModal('paymentmethod-modal', {id: paymentmethod.id},afterSave,{width: '800px'})"
                                        size="sm"
                                        variant="primary"
                                        class="mr-2"
                                        :title="$t('common.title.edit')"
                                    >
                                        <font-awesome-icon icon="edit"/>
                                    </b-button>

                                    <b-button @click="deletePaymentmethod(paymentmethod.id)"
                                              size="sm"
                                              :disabled="paymentmethod.base_method"
                                              variant="danger"
                                              :title="$t('common.title.delete')"
                                    >
                                        <font-awesome-icon icon="trash"/>
                                    </b-button>
                                </b-td>
                            </b-tr>
                        </draggable>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable"

export default {
    name: 'Settings-paymentmethods',
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            kvaPaymentmethods: [],
            paymentmethods: [],
            dragging: false,
        }
    },
    methods: {
        ...mapGetters('Paymentmethod', ['getPaymentmethods', 'getKvaPaymentmethods']),
        shown() {
            const paymentmethodsFetch = this.$store.dispatch('Paymentmethod/fetchPaymentmethods')
            const kvaPaymentmethodsFetch = this.$store.dispatch('Paymentmethod/fetchKvaPaymentmethods')

            Promise.all([paymentmethodsFetch, kvaPaymentmethodsFetch])
                .then(() => {
                    this.paymentmethods = this.getPaymentmethods()
                    this.kvaPaymentmethods = this.getKvaPaymentmethods()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        changeMap: function (event, id) {
            Promise.all([this.$store.dispatch('Paymentmethod/setPaymentmethodMap', {
                maps: event,
                id: id
            })]).then(() => {
                this.shown()
            })
        },
        afterSave() {
            this.$store.dispatch('CommonData/fetchCommonData')
            this.shown()
            this.$root.afterSettingsUpdate()
        },
        deletePaymentmethod(id) {
            this.$removeConfirm('Paymentmethod/deletePaymentmethod', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>